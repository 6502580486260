import React from 'react'
import { Link } from 'gatsby'
import { useTheme } from '../Theming'


export default () => {
  const theme = useTheme()
  return (
    <React.Fragment>
      <Link to="#" activeClassName="active" aria-label="View blog page">
        About
      </Link>

    </React.Fragment>
  )
}
